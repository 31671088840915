@import "../../assets/css/_variables.scss";
@import "../../assets/css/_colors.scss";
@import "../../assets/css/_responsive.scss";

div.multi-select-container {
  min-height: 34px;
  min-width: 190px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  &.label-above {
    &::after {
      bottom: calc(25% - (5.48px / 2));
    }
  }
  label.multi-select-label {
    color: var(--primary-color);
    font-size: 18px;
    direction: rtl;
  }
  input {
    min-height: 35px;
    width: 100%;
    cursor: pointer;
    font-size: 12px;
    border: none;
    &::placeholder {
      color: #000;
    }
  }
  &::after {
    content: '';
    border-left: 5.48571px solid transparent;
    border-right: 5.48571px solid transparent;
    border-top: 5.48571px solid #000;
    bottom: calc(50% - 5.48px / 2);
    height: 0;
    left: 8px;
    position: absolute;
    pointer-events: none;
    width: 0;
  }
  > div.multi-select-list-wrapper {
    background-color: #fff;
    position: absolute;
    z-index: $z-dropdown;
    top: 0;
    height: 140px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
    //padding: 0 0 10px;
    &.with-label {
      top: -35px;
      ul.list {
        li.label-display {
          font-size: 14px;
        }
      }
    }
    &.no-scroll {
      height: auto;
      overflow: visible;
    }
    &.align-bottom {
      top: 100%;
      width: 100%;
      //bottom: 0;
    }
    ul.list {
      height: auto;
      li {
        font-size: 12px;
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        margin-left: 0;
        height: 35px;
        cursor: pointer;
        &:not(:last-child) {
          //margin-bottom: 15px;
        }
        &:hover,
        &:focus {
          background-color: rgb(238,238,238);
        }
        &.label-display {
          font-weight: 500;
          font-size: 14px;
          height: 35px;
        }
        div.checkbox-wrapper-container {
          //margin-bottom: 0;
          height: 100%;
          width: 100%;
          label {
            margin-bottom: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:not(.colored-box) {
              > div {
                border-color: rgba(0, 0, 0, 0.54);
              }
            }
            &.checked {
              span {
                color: $bright-blue;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 959px) {
  div.multi-select-container {
    &::after {
      border-left-width: 4.48571px;
      border-right-width: 4.48571px;
      border-top-width: 4.48571px;
    }
    > div.multi-select-list-wrapper {
      ul.list {
        li {
          height: 25px;
        }
      }
    }
  }
}