@import "../../assets/css/_responsive";

div.form-container {
  position: relative;
  display: flex;
  direction: rtl;
  width: 100%;
  height: 100%;
  font-size: 15px;

  form.contact-us-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0;

    .form-field {
      font-family: 'yed', 'arial';
      width:95%;
      max-width: 462px;
      min-width: 300px;
      height: 50px;

      border: 0.3px solid #707070;
      border-radius: 25px;
      opacity: 1;
     

      &.textarea-field {
        height: 183px;
        resize: none;
        padding-top:10px;
      }
    }

    .submit-contact {
      background-color: #1ABCC5;
      color: white;
      border: none;
      height: 47px;
      width: 162px;
      font-family: 'Fb Reforma', 'arial';
      border-radius: 24px;
      outline: none;
      margin: 0 auto;
      font-size: 20px;
    }
  }
}

