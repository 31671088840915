@import "../../assets/css/colors";

div.task-item {
  div.task-detail{
    .icon-task{
      display: inline-block;
      width: 2rem;
      margin-left: 0.5rem;
      text-align: center;
    }
  }
  div.max-limit-volunteer{
    margin: 1.5em 0;
    font-weight: bold;
    span#bottom-text-login {
      color: var(--primary-color);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
// Modal Volunteer Content design
div.task-item-modal {
  section[role="body"] {
    div.modal-volunteer-content {
      //padding: 3em;
      width: 537px;
      height: 359px;
      display: flex;
      flex-direction: column;
      padding: 2em 2em;
      max-width: 90vw;
      div.contact-text{
        text-align: center;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 3em;
        color: var(--text-color);
        h2{
          margin-bottom: 0.5em;
          font-size: 30px;
        }
        h4{
          font-size: 20px;
        }
      }
    }
    div.actions {
      display: flex;
      justify-content: space-between;
      margin-top: 2em;
      div.helpi-button-container{
        button.helpi-button{
          padding: 0.5em 1.6em;
          font-size: 18px;
          font-weight: 500;
          &.transparent{
            background: transparent;
            color: var(--secondary-color);
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}