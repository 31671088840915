@import '../../assets/css/responsive';

.backgroundImg-warper {
  position: relative;
  overflow: hidden;
  // background-color: #fcfcfc;

  .backgroundImg-tite-box{
    position: relative;
    margin-right: auto;
    text-align: end;

      .backgroundImg {
      // left: 0%;
      width: 78%;
      // -webkit-transform: translate(-69px, 69px);
      // transform: translate(-67px, 28px);
      // position: absolute;
      // z-index: 0;
      // top: -877px;
    }

    
    .success-page-title {
      position: absolute;
      top: 13%;
      // left: 900px;
      width: 100%;
      text-align: center;
      display: block;

      font-size: 50px;
      font-weight:800 ;
      &.WL{
          left: 800px;
          font-size: 35px;

      }

    }
  }
}

.success-page {
  // background-color: red;
  position: relative;
  margin-top: -100px;
  // overflow: hidden;
  // margin-top: 440px;


  .sub-container {
    position: relative;
    z-index: 1;
  }

  
  .cases {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
        // padding-top: 450px;
    // margin-top: 11px;

    div.case-card {
      box-shadow: none;
      display: flex;
      justify-content: center;
      // box-shadow: -10px 20px 40px #0000000F;

      .case {
        border-radius: 22px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: -10px 20px 40px #0000000F;
        opacity: 1;
        // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
        margin-bottom: 65px;
        margin: 0px 0.9vw calc(3vw + 10px) 0.9vw;
        width: 20vw;
        height: 16vw;
        min-width: 260px;
        min-height: 189px;

        .case-details {

          // width: 315px;
          height: 50%;
          margin: calc(0.5vw + 5px) calc(1vw + 10px);
         

          padding: 2px;
          overflow: auto;
          
         

          .case-title {
            overflow: hidden;
            height: auto;
            margin-bottom: 0;
            text-align: center;
            // font: normal normal 600 18px/24px 'Ploni ML v2 AAA';
            letter-spacing: 0px;
            color: #000000;
            font-weight: bold;
            opacity: 1;
          }
        }
        
        .case-details:hover::-webkit-scrollbar-thumb {
          background-color:  #a8bbbf;
        }
        .case-details::-webkit-scrollbar {
          width: 5px;
        }
        
        .case-details::-webkit-scrollbar-track {
          background-color:transparent;
        }
        
        .case-details::-webkit-scrollbar-thumb {
          background-color:transparent;
          border-radius: 5px;
          // border: 6px solid transparent;
          // background-clip: content-box;
        }

      }
    }
  }
}

// div.container-success{
//   max-width: 1316px;
//   // width: 70vw;
//   // margin: auto;
// }


@include  media-max($layout-breakpoint-md){
  
  .backgroundImg-warper {

    // .backgroundImg {
      
      // left: -350px;
      // width: 2000px;
      // top: -554px;
  
    // }
    .backgroundImg-tite-box{
      .success-page-title {

        // top: 87px;
        // left: 571px;
        font-size: 45px;
        font-weight:800 ;
          &.WL{
            left: 470px;
            font-size: 30px;
    
        }
      }
  
    }

  }
  
  .success-page {
    // margin-top: 365px;
    margin-top: 0px;

  }
      
}
    



@include media-max($layout-breakpoint-sm){

 

  .backgroundImg-warper {

    // .backgroundImg {
      
      // left: -135px;
      // width: 1000px;
      // top: -290px;
  
    // }
    .backgroundImg-tite-box{
      .success-page-title {

        // top: 28px;
        // left: 255px;
        // font-size: 30px;
        font-weight:800 ;
        &.WL{
          font-size: 25px;
          top: 205px;
          left: 170px;
  
        }
      }
  
    }
    

  }
  
  .success-page {
    // margin-top: 41px;
    margin-top: 0px;

  }



}

@include media-max(500px){

 

  .backgroundImg-warper {

    // .backgroundImg {
      
    //   left: -12px;
    //   width: 100%;
    //   top: -70px;
  
    // }
    .backgroundImg-tite-box{
      .success-page-title {

        // top: 30px;
        // left: 40v;
        font-size: 17px;
        font-weight:800 ;
        &.WL{
  
          top: 155px;
          left: 100px;
          font-size: 15px;
        }
     
      }
  
    }
  

  }
  
  .success-page {
    margin-top: 0px;

  // padding-top: 45px;
  }



}


