@import '../../assets/css/colors';
@import "../../assets/css/_responsive.scss";
div.cases-grid-container {
  //--container-case-grid: 1100px;
  --container-case-grid: 960px;
 


  margin-bottom: 40px;
  max-width: var(--container-case-grid);

  div.sort-and-count {
    display: flex;
    justify-content: space-between;
  }

  div.sort-options {
    margin: 0 0 10px 0;

    span.sort-option {
      cursor: pointer;
      margin-left: 3px;

      &.selected {
        color: var(--primary-color);
      }

      &:last-child::after {
        content: none;
      }
    }

    span.divider {
      margin-left: 3px;
    }
  }

  div.cases-paging-title {
    margin-bottom: 10px;
  }

  ul.cases-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    li {
      flex: 0 0 300px;
      //max-width: 300px;
      max-height: 100%;
      display: flex;
      justify-content: space-between;

      // margin-bottom: 40px;
      &:not(:nth-child(3n)) {
        margin-left: calc((var(--container-case-grid) - (300px * 3)) / 6);
      }

      div.case-card {
        width: 100%;
        border-radius: 0 0 10px 10px;
        background-color: white;

        div.case {
          >div:not(.authorized-case) {

            img.case-img,
            div.company-case-frame {
              // height: calc((var(--container-case-grid) * 0.31) / 1.32);
            }
          }
        }
      }
    }
  }

  div.load-more-button {
    text-align: center;

    .helpi-button.more-cases {
      font-size: 16px;
      padding: 10px 25px;
      margin: 4rem 0 6rem 0 ;
    }

    button {

      border-radius: 2rem;
      ;
      background-color: #f57c20;

      &:disabled {
        cursor: inherit;
      }
    }
  }

  &.guest-user {
    ul.cases-grid {
      li {
        div.authorized {
          cursor: pointer;

          div.case {

            >div:not(.authorized-case),
            >div:not(.authorized-case) a {
              opacity: 0.5;
              pointer-events: none;
            }

            >div.authorized-case {
              opacity: 0.7;
              font-size: 1rem;
              padding: 35% 20px;
              border-radius: 0 0 8px 8px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $layout-breakpoint-sm) {
  div.cases-grid-container {
    .new-bacround{
      position: absolute;
      transform: translate(-26%, -54%);
      z-index: -1; 
    }
  }
}
@media (max-width: $layout-breakpoint-sm) {
  .new-bacround {
    display: none;
  }
}
@media (max-width: 959px) and (min-width: 600px) {
  div.cases-grid-container {
    --container-case-grid: 600px;

    ul.cases-grid {
      justify-content: space-between;

      li {
        max-width: 280px;
        flex: 0 0 280px;

        &:not(:nth-child(3n)) {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 599px) {
  div.cases-grid-container {
    --container-case-grid: 90vw;

    div.sort-options,
    div.cases-paging-title {
      text-align: center;
      font-size: 12px;
    }

    div.sort-and-count {
      margin-bottom: 20px;
    }

    div.sort-options {
      div.select-container {
        width: 90px;

        >div>div {
          &::after {
            border-top-color: var(--text-color);
            left: 0;
          }
        }

        div[data-react-toolbox=input] {
          padding-top: 0;
          padding-bottom: 0;

          input {
            font-size: 12px;
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 0;
            color: var(--text-color);
          }
        }
      }
    }

    ul.cases-grid {
      flex-direction: column;
      align-items: center;

      li {
        max-width: 300px;

        &:not(:nth-child(3n)) {
          margin-left: 0;
        }

        div.case-card {
          div.case {
            >div:not(.authorized-case) {

              div.company-case-frame,
              img.case-img {
                height: calc(300px / 1.32);
              }
            }
          }
        }
      }
    }
  }
}