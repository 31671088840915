.principles {

    h1 {
        text-align: center;
        margin-top: 6rem;
    }

    .principles-line {
        display: flex;
        gap: 2.5%;
        justify-content: center;

        .principle-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            border-radius: 20px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 20px 0 0;
            text-align: center;
            width: 20%;
            aspect-ratio: 1 / 1;

            img {
                width: 100%;
                height: auto;
                max-width: 45%;
                max-height: 45%;
                object-fit: contain;
                margin: 10% 5% 0;
            }

            p {
                font-size: 80%;
                font-weight: bold;
            }
        }
    }

}