@import "../../assets/css/responsive";
@import "../../assets/css/variables";
@import "../../assets/css/colors";

.section-seperator {
  width: 100%;
  border-bottom: 1px solid #A9DCE3;
  height: 20px;
  text-align: center;
}




footer {
  // border:  #898B8D 1px solid;
  width: 100%;
  height: 17.5vw;
  max-height: 360px;

  // background: #FCFCFC 0% 0% no-repeat padding-box;
  background-size: 100% 100%;
  position: relative;


  &.login-route {
    display: none;
  }
  .social-links{
    display: none;
  }
  .joinus-container{
    display: none;
  }

  .footer-container {
    background: url('../../assets/img/footer/Mask-Group-39.png');
    width: 100%;
    height: 100%;

    background-size: 100% 100%;


    .section-seperator {
      display: none;
    }

  }
  .section-seperato{
    display: none;
  }

  .logo {
    position: absolute;
    background: url('../../assets/img/footer/Group_114.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    bottom: 3vw;
    right: 10vw;
    width: 13vw;
    height: 3.2vw;

  }

  .sponsor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text {
      color: #fff;
      display: flex;
      justify-content: flex-end;
      margin-bottom: -15px;
      padding-left: 5px;
      direction: rtl;
    }

    img {
      max-width: 400px;
    }
  }

  .footer-links {

    position: absolute;
    bottom: 2vw;
    left: 5vw;
    width: 61vw;
    list-style: none;
    padding: 0;
    display: flex;
    align-items: baseline;

    /* Align items to the baseline */
    // justify-content: center;
    span.link,
    li.link {
      cursor: pointer;
      color: #898B8D;
      font-size: 15px;
      font-family: 'Fb Reforma', 'ariel';
      line-height: 11px;

      &:not(:last-child):not(.contact-us) {
        border-left: #F47B20 2px solid;

      }

      &:not(:last-child) {

        padding-left: 2%;
      }

      &:not(:first-child) {
        padding-right: 2%;
      }

      &:last-child:after {
        content: none;
      }

      a {
        text-decoration: none;
        color: #898B8D;
        font-size: 15px;

        img {
          vertical-align: middle;
          //  vertical-align: 'middle';
          //   margin-right: '5px'; 
        }

        span {
          padding-right: 18px;
          vertical-align: inherit;
        }

      }

      follow-us {
        display: flex;

        &.img {
          width: 1vw;
          height: 1vw;
        }

      }
    }
  }


  div.tav {
    position: absolute;
    height: 73%;

    left: 7vw;
    bottom: 4.5vw;

    img {
      display: block;
      height: 100%;
      width: auto;
    }
  }
}

@include media-max(1300px) {
footer{

  .footer-links {
  span.link,
  li.link {
    font-size: 14px;
    a{
      font-size: 14px;
    }
  }
}
}

}
@include media-max($layout-breakpoint-md) {
  footer {
    .social-links {
      max-width: 100%;
      flex: 1 1 50%;
    }

    .joinus-container {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 50%;
    }

    .footer-container {}
  }
}

@include media-max($layout-breakpoint-sm) {
  footer {
    border-top:  #898B8D 1px solid;
    // width: 100%;
 height: initial;
    width: 100%;

    .joinus-container{
      max-width: 400px;
      width: 400px;
      color:  #898B8D ;
      direction: rtl;
      text-align: center;
      div.follow-text{
        margin-bottom: 10px;
      }
      a.facebook{
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width:50px;
          height:50px;
        }
        div.helpiFacebook{
          margin-top: 5px
        }
      }
    }
    .social-links {
      height: 100px;
      max-width: 75%;
      margin: 0 auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }
  
  
    .footer-container {
      background: none;

     max-width: 80%;

    margin: auto;
    padding: 20px 0;
      // all: initial;
      .section-seperator{
        display: block;
        border-bottom: 1px solid #F47B20;
      }
    }


    .logo {
   
      all: initial;
    }

    .link {
      // background-color: #F47B20;
      border: unset !important;
      padding-right: 0 !important;
      padding: 0 !important;
      span{
        padding: 0 !important;
      }
    }

    .footer-links {
      
      // Reset all styles for .footer-links
      all: initial;
      // position: absolute;
      list-style: none;
    // padding: 0;
    margin: 20px 0;
    flex-direction: column;
      position: static;
      display: flex;
      justify-content: center;

    }

    a {
      img {
        // Reset all styles for img within anchor tags
        all: initial;
      }
    }

    li.link {
      margin-bottom: 0.7em;
      border-left: none;
      font-size: 14px;
      font-weight: bold;

      &:after {
        content: none;
        border-left: none;
      }
    }

    div.tav {
      position: absolute;
    height: 51%;
    top: 148px;
    left: 148px;
  
      img {
        display: block;
        height: 100%;
        width: auto;
    }
    
      }


    }

    .follow-us img {
      all: initial;
      display: none;
    }
  }



@include media-max($layout-breakpoint-xs) {
  footer {
    .social-links {
      height: auto;
      flex-flow: row wrap;

      .sponsor-container,
      .joinus-container {
        flex-basis: 100%;
      }

      .sponsor-container {
        display: flex;
        flex-flow: column;
        align-items: center;

        .text {
          //align-self: flex-end;
          margin-bottom: 1em;
        }

        img {
          max-width: 100%;
        }
      }

      .joinus-container {
        align-items: center;
        margin-top: 2em;
      }
    }

    div.tav {
      height: 40%;
      top: 185px;
      left: 25px;
    }
  }
}