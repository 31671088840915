@import '../../../assets/css/_colors.scss';
@import "../../../assets/css/responsive";



div.login-profile-wrapper {

  min-width: 130px;;
  display: flex;
  align-items: center;
  justify-content: center;

  top: 30%;
  a.login-button,
  div.user-dropdown{
  
    width: 145px;
    height:100%;  
    border-radius: 40px;
    border: #1ABCC5 2px solid;
    color: #1ABCC5;
    font-weight: bold;
    background-image: url('../../../assets/img/header/people.svg');
    background-size: 12% auto;
    background-repeat: no-repeat;
    background-position: 86% center;
    padding-right: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
		cursor: pointer;
  
  }

  li:hover {
    color: var(--menu-link-hover-color);
  }

  a.login-button {
    background-color: rgba(0,0,0,0);
    position: relative;

  }
  div.user-dropdown {
    position: relative;
    div.name {
      //padding-bottom: 10px;
      position: relative;
      //display: flex;
      //align-items: baseline;
      &:after{
       
    // content: '';
        border-left: 5.48571px solid transparent;
        border-right: 5.48571px solid transparent;
        border-top: 5.48571px solid var(--menu-link-color);
        bottom: calc(50% - 5.48px / 2);
        height: 0;
        right: 100%;
        position: absolute;
        pointer-events: none;
        width: 0;
        margin-right: 9px;
      }
    }
    ul.list-wrapper {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: $header-bg;
      display: none;
      &.open {
        display: block;
      }
      li {
        text-align: center;
      
        &:last-child {
          border-bottom: none;
        }
        > * {
          display: block;
          padding: 10px 0;
          cursor: pointer;
        }
      }
    }
  }
}


@include media($layout-breakpoint-sm, $layout-breakpoint-md) {
  div.login-profile-wrapper {
    left: 15px;
  }
}