table.volunteer-list-table {
  border-spacing: 0.2em 1.5em;
  width: 100%;
  thead {

  }
  tbody {
    tr {
      font-size: 14px;
      td.remove-cell {
        cursor: pointer;
        div.icon-container{
          display: flex;
          i.material-icons{
            font-size: 21px;
          }
        }
      }
    }
  }
}