@import "../../assets/css/colors";

div.validated-input-container{
  position: relative;
  div[data-react-toolbox="input"]{

    input{
    
      &:disabled{
        color: rgb(178, 177, 177)!important;
      }
      &:focus:not([disabled]):not([readonly]){
        ~ span.bottom-bar{
          &:before{
            background-color: var(--primary-color);
          }
          &:after{
            background-color: var(--primary-color);
          }
        }
        ~ label:not(.GRQEP){
          color: var(--text-color);
        }
      }
      ~ label{
        left: auto;
        right: 0;
        color: var(--text-color);
      }
      ~ .hint {
        left: auto;
        right: 0;
      }
    }
    &.errored{
      input {
        color: red;
        &._2k5Jz {
          padding-left: 5px;
        }
        &:focus:not([disabled]):not([readonly]) {
          ~ span.bottom-bar {
            &:before {
              background-color: red;
            }
            &:after {
              background-color: red;
            }
          }
          label.description-label{
            span{
              color: red;
            }
          }
        }
      }
    }
  }
  &.auth-input {
    width: 344px;
    div[data-react-toolbox="input"] {
      input {
        height: 44px;
        border-bottom: 1px solid $border-gray-green;
        &:focus:not([disabled]):not([readonly]) {
          ~ label:not(.GRQEP) {
            color: var(--primary-color);
          }
        }
        ~ label {
          color: var(--primary-color);
          top: 6px;
          font-size: 14.4px !important;
        }
      }
    }
    &.with-tooltip {
      div[data-react-toolbox="input"] {
        input {
          ~ label.description-label {
            //font-size: 18px;
            display: inline-flex;
            align-items: center;
            //position: relative;
            //top: -50px;
          }
          ~ div.tool-tip-wrapper {
            position: absolute;
            top: 6px;
            right: 155px;
            //top: -53px;
            //position: absolute;
            //top: -6px;
          }
        }
      }
    }
  }
  div.show-password {
    position: absolute;
    display: none;
    line-height: 1;
    justify-content: center;
    align-items: center;
    left: 0;
    top: calc(50% - (24px / 2));
    font-size: 18px;
    color: var(--secondary-color);
    cursor: pointer;
    &.show {
      display: flex;
    }
  }
}

div.validated-input-container.new{
  position: relative;
  div[data-react-toolbox="input"]{
    padding: 5px 0px;
    input{
      &:disabled{
        color: rgb(178, 177, 177)!important;
      }
      &:focus:not([disabled]):not([readonly]){
        ~ span.bottom-bar{
          &:before{
            background-color: transparent;
          }
          &:after{
            background-color: transparent;
          }
        }
        ~ label:not(.GRQEP){
          color: var(--text-color);
        }
      }
      ~ label{
        left: auto;
        right: 0;
        color: var(--text-color);
      }
      ~ .hint {
        left: auto;
        right: 0;
      }
    }
    &.errored{
      input {
        color: red;
        &:focus:not([disabled]):not([readonly]) {
          ~ span.bottom-bar {
            &:before {
              background-color: red;
              width: 45%;
            }
            &:after {
              background-color: red;
              width: 45%;
            }
          }
          label.description-label{
            span{
              color: red;
            }
          }
        }
      }
    }
  }
  &.auth-input {
    // width: 100%;
    div[data-react-toolbox="input"] {
      input {
        height: 44px;
        border:  0.5px solid #707070;
        border-radius: 25px;
        padding: 0px 25px;
        &:focus:not([disabled]):not([readonly]) {
          ~ label:not(.GRQEP) {
            color: transparent;
          }
        }
        ~ label {
          color: var(--primary-color);
          top: 6px;
          font-size: 14.4px !important;
        }
      }
    }
    &.with-tooltip {
      div[data-react-toolbox="input"] {
        input {
          ~ label.description-label {
            //font-size: 18px;
            display: inline-flex;
            align-items: center;
            //position: relative;
            //top: -50px;
          }
          ~ div.tool-tip-wrapper {
            position: absolute;
            top: 6px;
            right: 155px;
            //top: -53px;
            //position: absolute;
            //top: -6px;
          }
        }
      }
    }
  }
  div.show-password {
    position: absolute;
    display: none;
    line-height: 1;
    justify-content: center;
    align-items: center;
    left: 15px;
    top: calc(50% - (24px / 2));
    font-size: 18px;
    color: var(--secondary-color);
    cursor: pointer;
    &.show {
      display: flex;
    }
  }
}
span._2k5Jz .error-label{
    padding-left: 5px;
  }
