@import "../../assets/css/_variables.scss";
@import "../../assets/css/_colors.scss";
@import "../../assets/css/responsive";


div.ask-help.container {
  font-size: 15px;
  max-width: 912px;
  margin-bottom: 1em;
  #title-container {
    text-align: center;
    margin: 25px 8px 35px 8px;
    h2{
      font-size: 30px;
    }
  }
  form#requestForm,
  form#emergency-request-form{
    div.inner-section{
      margin-top: 2.5em;
    }
    div.head-title{
      //margin: 2.5em 0;
    }
    div.help-sub{
      label {
        display: block;
      }
    }
    div.description-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      div.example-text {
        width: 30%;
        display: flex;
        flex-direction: column;
      }
    }
    div.location-auto-complete-wrapper{
      margin: 1em 0;
    }
    div.radio-container{
      margin-top: 2.5em;
      > * {
        width: 250px;
      }
    }
    span.indicator{
      color: #4CAF50;
      font-size: 15px;
      &.error{
        color: #D50808;
      }
    }
    div.time-range-container {
      span {
        color: #d50808;
        margin-top: 10px;
        display: block;
      }
      div.multi-select-container {
        width: 250px;
        border-radius: 2px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        input {
          //color: var(--text-color);
        }
        div.multi-select-list-wrapper {
          padding: 10px;
          height: auto;
        }
      }
    }
    div.date-container {
      ._36UDg {
        height: auto;
      }
    }

    div.time-range-container {
      margin-top: 10px;
    }
    div.date-time-case-picker-container {
      margin-top: 0.5em;
      margin-right: 0.9em;
    }
    div.user-info-container{
      label{
        display: block;
        a{
          color: #1ABCC5;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      span{
        display: inline-block;
      }
      div.info{
        margin: 1em 0;
      }
    }
    div.checkbox-wrapper-container label.helpi-checkbox {
      width: 40%;
      cursor: pointer;
      &.checked {
        color: rgba(255, 171, 64, 0.87);
        > div {
          background-color: rgba(255, 171, 64, 0.87);
          border-color: rgba(255, 171, 64, 0.87);
        }
      }
      span[data-react-toolbox="label"] {
        font-family: 'Fb Reforma', 'arial';
        font-weight: normal;
        color: var(--text-color);
        font-size: 15px;
        line-height: 20px;
      }
    }
    div.validated-input-container {
      display: inline-flex;
      width: 156px;
      direction: rtl;
      margin-left: 0.7em;
      & > * {
        color: var(--text-color);
        font-size: 15px;
        width: 100%;

      }
      & > *, span {
        font-family: 'Fb Reforma', 'arial';
        font-weight: normal;
      }
      label{
        right: 0;
      }
    }
    div.multiple-volunteers-container{
      div.validated-input-container {
        width: 166px;
      }
    }
    div.someone-else-container {
      div.someone-else-form-container {
        & > label {
          display: block;
          margin: 1em 0;
        }
      }
    }
    div.button-container{
      display: flex;
      justify-content: center;
      button.helpi-button{
        height: 30px;
        font-size: 17px;
        line-height: 2px;
        min-width: 130px;
        &[disabled]{
          cursor: not-allowed;
          pointer-events: none;
          background-color: transparent;
          color: rgba(0,0,0,0.38);

        }
      }
    }
  }
}


.address-status-ok {
  color: green;
}

.address-status-bad {
  color: red;
}
.required-indicator:before {
  color: red;
  font-size: 20px;
  content: " *";
}

#location-address-container {
  width: 500px;
}

.vol-img {
  width: 100%;
  height: auto;
}

// hide the mdDatePicker small arrow button. It overlaps with the date text when in RTL.
.md-datepicker-expand-triangle {
  display: none;
}

//.case-type-square {
//  cursor: pointer;
//  text-align: center;
//  font-size: 20px;
//  outline: none;
//  width: 100%;
//  background-color: #666;
//  color: $light-sky-blue;
//}

//#title-container {
//  text-align: center;
//  margin: 8px;
//}

.gac-input {

  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

  background-color: #fff;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;

}

.pac-input:focus {
  border-color: #4d90fe;
}

.help-sub{
  label{
    strong{
      color: red;
    }
  }
  ul{
    list-style: disc inside;
  }
}

.text-input{
  margin-top: 1em;
  md-input-container {
    label{
      padding-right: 10px !important;
    }
    textarea {
      min-height: 190px !important;
      border: 1px solid #6f6f6f;
    }
  }
  .example-text{
    strong{
      text-decoration: underline;
    }
    p{
      margin-top: 0;
    }
  }
}

@include media-max($layout-breakpoint-sm) {
  div.ask-help.container.ask-help-container {
    max-width: 600px;
    div.text-area-input-container div[data-react-toolbox="input"] {
      margin-left: 6em;
    }
  }
}

@include media-max($layout-breakpoint-xs) {
  div.ask-help.container.ask-help-container {
    margin: 0 16px;
    max-width: 600px;
    form#requestForm {
      margin: 0 16px;
      div.help-sub {
        margin-top: 2em;
      }
      div.description-container {
        flex-direction: column;
        div.text-area-input-container{
          width: 100%;
          div[data-react-toolbox="input"] {
            margin-left: 0;
            margin-bottom: 1em;
          }
        }
        div.example-text {
          width: 100%;
        }
      }
      div.date-container{
        div.date-time-container{
          div.date-time-case-picker-container{
            flex-direction: column;
            align-items: flex-start;
          }
          div.time-container{
            margin-top: 1em;
          }
        }
      }
    }
    #squaresMobile {
      .layout-row {
        flex-wrap: nowrap;
      }
    }
    #location-address-container {
      max-width: 77vw;
    }
  }
}