@import "../../assets/css/colors";

div[data-react-toolbox="dialog"] {
  width: auto;
  section[role="body"] {
    padding: 0;
    font-family: 'Fb Reforma', 'arial';
    font-size: 15px;
    div.modal-header {
      background: var(--primary-color);
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.6em 1.1em 0.4em;
      height: 64px;
      font-size: 20px;
      color: white;
      h2{
        font-size: inherit;
      }
      span.close-container{
        padding: 0 8px 2px 0;
        display: flex;
        cursor: pointer;
        font-size: inherit;
      }
    }
    div.content{
      //padding: 1em;
    }
  }
}

