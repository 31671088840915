@import "../../assets/css/_responsive.scss";

.about-us-main {
    .main {
        margin: 0 12%;
        font-size: 1rem;
    }


    h1 {
        font-weight: 600;
        letter-spacing: 1px;
        margin: 3rem 0 1rem 0;
    }

    h3 {
        font-size: 1.5rem;
    }

    .background {
        position: absolute;
        z-index: -1;
    }

    .orange-stripe {
        width: 100%;
        left: 0;
        transform: translateY(-23%);
    }

    .light-blue {
        width: 85%;
        right: 0;
        top: 56rem;
    }

    @include media-min(1300px) {
        .main {
            font-size: 1.5rem;

            .globe {
                height: auto;
            }

            h3 {
                font-size: 2rem;
            }

            h1 {
                font-size: 3rem;
            }
        }

        .story {
            h1 {
                font-size: 3rem;
                padding-top: 13%;
            }

            p {
                font-size: 1.5rem;

            }

            h3 {
                font-size: 2rem;
            }
        }
    }

    @include media-max($layout-breakpoint-sm) {
        .background {
            display: none;
        }

    }
}