@import "../../assets/css/_responsive";

div.contact-container {

  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;
  align-items: center;

  .image-wrapper {
    width: 50%;
    padding: 5%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .contact-form {
    padding: 5%;

    width: 50%;

    .title-form {
      font-size: 55px;
      color: #474748;
      font-weight: 600;
      letter-spacing: 1.1px;
      margin-bottom: 2vw;


    }


    .send-success {
      margin-top: 0;
      font-size: 1.5em;
      color: #00a8c6;
      text-align: justify;
      padding-right: 13px;
      margin-top: 10px;
    }

  }
}



 @include media-max($layout-breakpoint-sm) {
  div.contact-container {


    .contact-form {
 
    .title-form {
      font-size: 50px;
    }
 
    .contact-us-form{
      .form-field{
      }
    }
  }

  }

 }
 @include media-max(850px) {
  div.contact-container {
    width: 50%;
    justify-content: center;
    margin: auto;
    .image-wrapper {
  display: none;
    }
    .contact-form {
      width: 100%;
      // padding: 3%;
    }
    .title-form {
      text-align: center;
      letter-spacing:0.7px;
    }
    .contact-us-form{
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

 }

//  @include media-max($layout-breakpoint-xs) {
//   div.contact-container {

//   // background-color: rgb(19, 187, 56);
//   }

//  }
