@import "../../assets/css/variables";
@import '../../assets/css/_colors.scss';
@import '../../assets/css/_responsive.scss';

div.auth-box {
  form.basic-details-form,
  form.social-worker-form {
    flex-flow: row wrap;
  }

  form.social-worker-form {
    max-width: 855px;
    justify-content: center;
    div.validated-input-container.auth-input {
      width: 400px;
      &:first-of-type {
        margin-left: 55px;
        margin-bottom: 0;
      }
      &:nth-of-type(3) {
        margin: 20px auto;
        //flex-basis: 100%;
      }
    }
    div.helpi-button-container {
      flex-basis: 100%;
      text-align: center;
        &.button.helpi-button.big {
          font-size: 17.6px;
          width: 130px;
          height: 40px;
          border-radius: 25px;
        }
    }
  }

  form.sign-up-form {
    div.validate-icon{
      color: green;
      position: absolute;
      top: 40%;
      right: 103%;
    }
    div.under-fields {
      justify-content: flex-end;
    }
  }
  @include media-max($layout-breakpoint-md) {
    form.basic-details-form,
    form.social-worker-form {
      flex-flow: column nowrap;
      div.outsider:nth-child(3),
      div.validated-input-container.auth-input:first-child {
        margin-left: 0;
      }
    }

    form.social-worker-form {
      div.validated-input-container.auth-input {
        &:nth-of-type(3) {
          margin-top: 0;
        }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
}

.checkbox-container{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.checkbox-item {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px; /* Add margin between checkbox items */
}
.HELPI_BUTTON_CLASSES.BIG {
  font-size: 17.6px;
  width: 130px;
  height: 40px;
  border-radius: 25px;
} 


.volenteer .outsider { 
  padding: 5px 0px; 
div.multi-select-container {
  display: inline;
  input {
    height: 44px;
    border: 0.5px solid #707070;
    border-radius: 25px;
    padding: 0px 25px;
    outline: 0;
    font-family: 'yed';
    font-size: 15px;
    width: 344px;
    &::placeholder {
      color: #666666; /* Placeholder color */
  }
    }
  }
}
div.validated-input-container.new.auth-input div[data-react-toolbox="input"] input {
  font-family: 'yed';
  &::placeholder {
    color: #666666 !important ; /* Change this to the desired color */
  
  }
}

div.validated-input-container.new div[data-react-toolbox="input"].errored input {
  color: #666666;
  &span._2k5Jz.error-label{
    padding-left: 5px;
  }
}
span._2k5Jz.error-label {
  padding: 0px 20px;
}


div.auth-box .wrapper-form-login h4 {
  padding-top: 8rem;
}
div.auth-box .wrapper-form-login .registered {
  color: $primary-color;
  padding-bottom: 1rem;
  cursor: pointer;
  text-decoration: underline;
}
div.checkbox-wrapper-container label.helpi-checkbox.colored-box.primary > div{
  border: 2px solid #79d8dd;
  background-color: white;
}
div.auth-box form.sign-up-form div.conditions {
  display: -webkit-box;
  font-size: 14px;
  margin-top: 20px;
}
.space{
  width: 5px;
}
div.checkbox-wrapper-container label.helpi-checkbox.colored-box span.checkbox-label.dXU7C {
  padding-left: 5px;
}
.newsletter {
  margin: 0px 10px 25px;
}
div.multi-select-container::after {
    content: " ";
    background: url('../../assets/img/header/ionic-ios-arrow-forward.svg');
    background-size: 100% 100%;
    border: 0;
    left: 15px;
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 9px;
    display: inline-block;
    margin-right: 5px;
  
}
div.validated-input-container.new.auth-input.with-tooltip div[data-react-toolbox="input"] input ~ label.description-label {
  left: auto;
  margin-top: 14px;
  right: 25px;
  color: var(--text-color);
}
div.validated-input-container.auth-input.new .validated-input-input label.description-label {
  left: auto;
  margin-top: 14px;
  right: 25px;
  color: var(--text-color);
}

div.error-message {
  color: $error-message;
  align-self: center;
}