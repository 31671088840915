@import "../../../assets/css/_responsive.scss";

.story {
  position: relative;
  margin: 0 12% 0 0;

  .spacer {
    position: relative;
    width: 75%;
    float: left;
    display: block;
    top: -33px;
    bottom: 0;
    shape-outside: inset(0 14% 0 0);
  }

  h1 {
    margin: 0;
    padding-top: 11%;
  }

  h3 {
    font-size: 1.5rem;
    margin-top: 20px;
  }

  p {
    font-size: 1rem;
    margin-left: 12%;
    margin-top: 20px;
  }

  h3 {
    color: #f57c20;
    font-weight: bold;
  }

  @include media-max($layout-breakpoint-sm) {
    .spacer {
      display: none;
    }
  }
}