.landmarks {
  h1 {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 6rem;
  }

  .timeline {
    position: relative;
    display: flex;
    flex-direction: column;

    .vertical-row {
      position: absolute;
      margin: 30px 50% 8rem 0;
      top: 0;
      bottom: 0;
      width: 2px;
      border-left: 2px dashed #b3b3b3;
      z-index: -1;
    }

    .landmark {
      display: flex;
      position: relative;
      height: 10rem;
      margin-top: 2%;

      a {
        color: #F47B20;
        text-decoration: underline;
        text-underline-offset: 3px;
      }

      .year {
        padding: 0.3rem 2rem;
        color: #ffffff;
        border-radius: 2.5rem;
        font-size: 1.5rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .details {
        font-size: 1.2rem;

        p {
          margin-top: 0;
          margin-bottom: 8px;
        }
      }

      &:nth-child(odd) .details {
        margin-right: 60%;
        // margin-left: 20%;
        width: 14rem;
      }

      &:nth-child(even) .details {
        position: absolute;
        width: 14rem;
        left: 0;
        margin-left: 60%;
      }

    }

  }

}