// general
$fa-font-path: "~font-awesome/fonts";
$max-container-width: 1170px;

// colors
$dodger-alpha: rgba(40, 85, 121, 0.6);
$dark-sky-blue: #4a90e2;
$dodger-blue: #53a2fd;
$dodger-light: #4a97ec;
$flat-blue: #3a71b0;
$green: #52e4c3;
$red: #ee6568;
$red_darker: #ee4e5b;
$bluey-grey: #94a8b3;
$duck-egg-blue: #ecf3fc;
$light-navy-blue: #315579;
$light-navy-blue-two: #284e79;
$black: #454545;
$duck-egg-blue-two: #ebf3fc;
$duck-egg-blue-four: #e4ecf4;
$denim: #3b587d;
$greyblue: #81a0c7;
$cloudy-blue-two: #b8c4d5;
$cloudy-blue: #cfd5de;
$white: #f8f8f8;
$true-white: #FFFFFF;
$white-two: #dfdfdf;
$white-three: #dedede;
$white-four: #f1f1f1;
$white-five: #fafafa;
$white-six: #c1c1c1;
$white-seven: #cad4d9;
$white-eight: #f4f4f4;
$white-nine: #e0dfe1;
$error-red: #a94442;
$error-pink: rgba(189, 45, 35, 0.1);
$error-salmon: #ff5361;
$light-periwinkle: #c9defb;
$light-salmon: #ff6168;
$salmon: #ff6666;
$pale-grey: #edeef0;
$pale-grey-three: #d2d3d5;
$pale-grey-four: #d4d4d4;
$pale-grey-five: #d3dce1;
$dark-grey: #6e6e6f;
$dark-grey-two: #838587;
$duck-egg-blue-three: #eaf3fc;
$pale-grey-two: #dfe5ee;
$light-periwinkle-two: #cfe3fd;
$denim-two: #34577d;
$salmon-pink: #ff7c7d;
$dark-sky-blue-three: #318fe1;
$dark-sky-blue-alpha: rgba(49, 143, 225, 0.8);
$blue-grey-two: #96a8b4;
$light-grey-blue: #859fc9;
$dark-sky-blue-two: #4990e2;
$lighter-navy: #12406d;
$light-navy: #13406d;
$faded-blue: #809ec8;
$very-light-blue: #d5e8ff;
$cloudy-blue-three: #a9bed7;
$cloudy-blue-four: #c1ccd7;
$light-navy-blue-three: #285579;
$input-glow: #4195fc;
$dark-grey-blue: #294968;
$dark-slate-blue: #152f4a;
$footer-box-blue: #587ce5;
$button-blue: #4c91dc;
$marine-blue: #003f6c;
$bluish-grey: #6c8e98;
$greyish: #ababab;
$light-green: #ccecdc;
$dark-green: #729e74;
$dark-green-two: #51a08e;
$light-mustard: #f6eada;
$mustard: #eeb265;
$mustard-two: #f4d35e;
$pinkish-grey: #cccccc;
$greyblue-two: #83a0c8;
$white-four: #fafafa;
$pale-blue: #f7fbff;
$pale-blue-two: #eff7ff;
$apple-green: #7ed321;
$squash: #f5a623;
$aqua-marine: #50e3c2;
$light-grey-blue-two: #a4b9cb;
$cool-grey: #9da7b0;

// sizes
$ex-small: 11px;
$small: 13px;
$medium: 15px;
$med-small: 14px;
$bigger: 18px;
$big: 22px;

// login page

$login-page-form-width: 415px;
$login-padding: 40px;

// general components

$input-padding: 10px;
$input-border-radius: 3px;
$default-border-radius: 5px;
$page-safe-margin: 120px;
$sub-header-spacing: 40px;
$sum-box-margin: 90px;

// modal
$modal-min-width: 370px;
$modal-table-max-height: 250px;

// filters
$dropdown-filter-br: 5px;
$color-filter-br: 3px;

// header
$header-padding: 10px;
$header-logo-width: 185px;
$header-sub-menu-item-min-width: 215px;
$header-page-title-height: 60px;
$header-page-title-height-big: 88px;
$header-height: 84px;
$header-mobile-height: 50px;

//footer
$footer-height-desktop: 211px;

// projects dropdown

$projects-dropdown-width: 375px;
$projects-dropdown-max-height: 350px;

// home page
$projects-container-margin: 145px;
$project-box-width: 370px;
$project-box-br: 5px;
$notification-padding: 20px;
$project-box-inner-margin: 20px;
$notification-font-size: $small;
$standard-br: 5px;
$stat-width: 250px;
$homepage-top-padding: 0px 10px 0 10px;

// table
$table-heading-cell-padding: 8px;
$table-cell-padding: 15px;
$table-cell-min-height: 50px;
$table-cell-max-width: 90px;
$table-dummy-width: 16%;
$title-cell-padding: 10px;

// report-table
$report-table-heading-border-radius: 5px;
$report-table-top-header-height: 30px;

// flat table
$flat-table-cell-height: 50px;

// scrollable table
$scrollable-table-title-cell-max-width: 250px;
$scrollable-table-title-cell-height: 50px;

$A4-w: 210mm;
$A4-h: 297mm;

$zindex: (
        modal: 9999,
        overlay:8000,
        header-footer: 7000,
        dropdown: 6000,
        above-main: 5000,
        main: 1
);

  // Admin tabs

$admin-tabs-container-width: 30%;
$admin-tab-active-color: rgb(34, 164, 183);
$admin-tab-active-color-a: rgba(34, 164, 183, 0.7);
$admin-tab-hover-color: rgb(250, 176, 114);

$gi-filter-search-btn-color: $admin-tab-active-color;

// Admin grid

$admin-grid-header-cell-color: rgb(144, 211, 203);
$admin-grid-header-cell-padding: 5px;
$admin-grid-cell-border: 1px solid #d4d4d4;
$admin-grid-required-action-color: red;


// Adming general

$admin-sap-height:  40px;

// Admin
$admin-panel-toolbar-height: 45px;
$admin-user-history-log-max-height: 250px;

// icons
$gi-icon-hover-color: rgb(144, 211, 203);

//success stories
$circle-dim: 250px;
$story-height: 200px;
$story-margin: 40px;

//z-index
$z-dropdown: 3;

//padding
$page-padding-top: calc(75px + 40px);