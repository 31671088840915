.radio-container{
  label[data-react-toolbox="radio-button"] {
    div[data-react-toolbox="radio"] {
      margin-left: 15px;
      border-color: var(--text-color);
      &.radioChecked {
        border-color: rgba(255, 171, 64, 0.87);
      }
      &:before {
        background-color: rgba(255, 171, 64, 0.87);
      }
      span[data-react-toolbox="ripple"] {
        span {
          background-color: rgba(255, 171, 64, 0.87);
        }
      }
    }
    span.radioLabel {
      font-family: 'Fb Reforma', 'arial';
      font-weight: normal;
      color: var(--text-color);
      font-size: 15px;
    }
  }
}