@import "../../assets/css/variables";
@import '../../assets/css/colors';
@import '../../assets/css/responsive';

// @keyframes flip-2-ver-left-bck {
//   0% {
//     transform: translateX(0) translateZ(-260px) rotateY(-180deg);
//   }
//   100% {
//     transform: translateX(0) translateZ(0) rotateY(0);
//   }
// }

// @keyframes flip-2-ver-right-bck {
//   0% {
//     transform: translateX(0) translateZ(-260px) rotateY(180deg);
//   }
//   100% {
//     transform: translateX(0) translateZ(0) rotateY(0);
//   }
// }


div.header-container.login-route {
  a.logo {
    height: 60px;
    width: 132px;
  }
}

div.new-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  width: 80%;
  max-width: 1000px; 
  height: 100%;
  margin: calc(2vw + 20px) auto;
  // margin-top:calc(2vw + 20px);
  background-color: white;
  &.sign-up{
    max-width: 1200px; 
    padding-left: 5%;
    padding-right: 5%;
    min-height: 700px;

  }
  div.mobile-switch-arrow {
    display: none;
  }

  div.helpi-button-container {
    .helpi-button {
      margin-bottom: 12px;
      &.orange {
        background-color: $secondary-color;
      }


    }
  }

  &.login {
    div.auth-box {
      // background-color: $bg-white;
      // transition: padding-right 0.5s ease;
      // padding-right: 25vw;
      > div {
        animation:  0.3s ease-in-out;
      }
      // div.auth-box {
      //   // padding-right: 0;
      // }
    }
  }
  &.sign-up {
    div.auth-box {
      // background-color: $bg-white;
      // transition: padding-left 0.5s ease;
      // padding-left: 25vw;
      > div {
        animation:  0.3s ease-in-out;
      }
      // div.auth-box {
      //   padding-left: 0;
      // }
    }
  }
  div.close {
    position: absolute;
    cursor: pointer;
    top: 30px;
    left: 35px;
    background-position: 50%;
    width: 30px;
    height: 30px;
    display: block;
    z-index: 10;
   
    background-image: url('../../assets/img/login/x-blue.svg');
    
  }
  div.box-auth-wrap {
    // flex: 1;
    display: flex;
    justify-content: space-between;
    // width: 100%;
    //max-width: 900px;
    div.auth-box {
      width: 50%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      // ul.helpi-dots {
      //   display: flex;
      //   justify-content: center;
      //   direction: rtl;
      //   margin-bottom: 15px;
      //   li {
      //     margin-left: 5px;
      //     &:last-child {
      //       margin-left: 0;
      //     }
      //     span.helpi-dot {
      //       display: block;
      //       width: 10px;
      //       height: 10px;
      //       border: 1px solid $secondary-color;
      //       border-radius: 50%;
      //       background-color: transparent;
      //       &.active {
      //         background-color: $secondary-color;
      //       }
      //     }
      //   }
      // }
    }
    // > div:not(.divider) {
    //   //max-width: 300px;
    //   flex: 1;
    // }
  }
  .box-img{
    width: 50%;
    align-items: center;
    display: flex;
  }

  .img-login {
    width: 100%;
    padding-top: 150px;
    div.helpi-button-container {
      width: 200px;
      
      button.helpi-button.big {
        font-size: 17.6px;
        width: 200px;
        height: 48px;
        //background-color: $primary-color;
        &.fb-button {
          font-size: 13.6px;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 3.6;
          border-color: #74a6f7;
        }
      }
    }
    &.sign-up {
      //left: 0;
      //right: auto;
      // transform: translate(-50vw + 12.5vw);
      //transform: translate();
      // transition: all 0.3s ease;
      //both images loaded at once, but stacking order prevents both from being seen
      // background-image: url('../../assets/img/login/sign-up-divider.png'), linear-gradient(90deg, rgba(250, 250, 250, 1) 0%, rgba(250, 250, 250, 1) 100%), url('../../assets/img/login/sign-up-divider-login.png');
      // background-size: cover;
      //left: 0;
    }
    &.login {
      // transform: translate(50vw - 12.5vw);
      //same as above, just sqitcing stacking order
      // background-image: url('../../assets/img/login/login.svg');
      // transition: all 0.3s ease;
      //right: 0;
      div.text-wrapper {
        //padding: 0 60px;
        // h2 {
        //   //padding: 0 60px;
        //   padding: 0 5%;
        // }
        // p {
        //   font-size: 20px;
        // }
      }
    }
    div.text-wrapper {
      height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      > * {
        text-align: center;
      }
      h2 {
        font-size: 32px;
      }
      p {
        font-size: 22.4px;
        white-space: pre-line;
        max-width: 100%;
      }
    }
  }
  form.basic-details-form,
  form.final-details-form,
  form.sms-verification-wrapper,
  form.social-worker-form {
    div.multi-select-container input {
      padding-left: 24px;
      font-family: 'yed', 'arial';
    }
    div.under-text {
      height: 125px;
      div.error-message {
        color: $error-message;
      }
    }
  }
  form.social-worker-form {
    position: relative;
    div.under-text {
      position: absolute;
      bottom: 48px;
      height: auto;
    }
  }
}

@include media-max($layout-breakpoint-sm) {

  .box-img{
    display: none !important;
  }
  .auth-box {
    width: 100% !important;
  }


  div.header-container.login-route {
    a.logo {
      display: block;
      background-position: 50%;
    }
  }
  div.auth {
    &.login {
      div.box-wrap {
        div.auth-box {
          padding-right: 35vw;
          padding-left: 0;
        }
      }
    }
    div.close.login,
    div.close.sign-up {
      background-image: url('../../assets/img/login/x-blue.svg');
      top: 20px;
      right: 20px;
      height: 19px;
      width: 19px;
      z-index: 10;
    }
    div.box-wrap {
      div.auth-box {
        padding-left: 35vw;
        padding-right: 0;
        div.validated-input-container.auth-input {
          width: 360px;
        }
      }
    }
    div.register-divider {
      width: 35vw;
      left: calc(50% - (35vw / 2));
      &.sign-up {
        transform: translate(-50vw + 17.5vw);
      }
      &.login {
        transform: translate(50vw - 17.5vw);
      }
      &.sign-up,
      &.login {
        div.text-wrapper {
          padding: 0;
          h2 {
            font-size: 35px;
            padding: 0;
          }
          p {
            font-size: 20px;
          }
        }
      }
      div.helpi-button-container {
        button.helpi-button.big {
          width: 170px;
        }
      }
    }
    form.basic-details-form {
      div.outsider {
        width: 360px;
      }
    }
  }
}

@include media-max($layout-breakpoint-xs) {
  div.header-container.login-route {
    height: 60px;
    box-shadow: none;
    background-color: $bg-white;
    &.transparent {
      background-color: transparent;
    }
    a.logo {
      width: 100px;
      height: 36px;
      right: calc(50% - 100px / 2);
      top: 10px;
    }
  }
  div.auth {
    padding-top: 60px;
    &.mobile-success {
      padding-top: 0;
    }
    div.img-divider {
      display: none;
    }
    div.mobile-switch-arrow {
      display: block;
      position: fixed;
      z-index: 10;
      top: 10px;
      right: 10px;
      height: 30px;
      width: 30px;
      background-size: cover;
      &.sign-up {
        background-image: url('../../assets/img/icons/arrow-right-blue.svg');
      }
      &.login {
        background-image: url('../../assets/img/icons/arrow-right-orange.svg');
      }
    }
    &.login {
      div.box-wrap {
        div.auth-box {
          padding-right: 0;
          padding-left: 0;
          .wrapper-form-login {
            width: 80%;
            margin: auto;
          }
          div.divider {
            color: $secondary-color;
          }
        }
      }
    }
    &.sign-up {
      div.box-wrap {
        div.auth-box {
          div.divider {
            color: $primary-color;
          }
        }
      }
    }
    div.box-wrap {
      div.auth-box {
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
        h4 {
          font-size: 30px;
          margin-bottom: 25px;
        }
        div.validated-input-container.auth-input {
          width: 78vw;
        }
        &.with-dots {
          ul.helpi-dots {
            z-index: 10;
            position: fixed;
            left: 20px;
            top: 24px;
          }
        }
        &:not(.with-dots) {
          padding-top: 0;
        }
      }
      div.mobile-switch-screen {
        position: absolute;
        width: 100vw;
        height: calc(100vh - 60px);
        top: 60px;
        left: 0;
        z-index: 1;
        div.to-login,
        div.to-sign-up {
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-size: cover;
          h2,
          div {
            color: #fff;
            pointer-events: none;
          }
          h2 {
            font-size: 34px;
          }
          div {
            font-size: 18px;
          }
        }
        div.to-login {
          background-image: url('../../assets/img/login/mobile-login-first-screen.png');
        }
        div.to-sign-up {
          background-image: url('../../assets/img/login/mobile-sign-up-first-screen.png');
        }
      }
    }
    form.basic-details-form {
      div.outsider {
        width: 78vw;
      }
    }
  }
}
