@import "../../assets/css/_responsive.scss";
@import '../../assets/css/colors';


div.registration-success-wrapper{
  background-color: #fafafa;
  width: 100vw;
  height: 70vh;
  top: 0;
  left: 0;
  div.wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    margin-top: 9vh; 


  }
  div.text-wrapper {
    position: relative; 
    background-color: white;
    letter-spacing: 1.2px;
    text-align: center;
    width: 75%;
    height: clamp(300px,25vw,25vw);
    display: flex;
    justify-content: center;
    align-items: center;
    .big-text {
      font-size: 3vw;
      font-weight: 600;
      margin-bottom: 2%;
    }
    .title-text ,.link{

      color: $primary-color;

    }
    .container-text{
      width: 60%;
      margin: auto;
      font-weight: bold;
      h3{
          font-size:clamp(15px,1.5vw,1.5vw);
      }
      h2 + h2 {
        margin-top: 20px;
      }
    }

    .left-image,
    .right-image {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute; 
        display: block;
        height: 80%;
        width: 22%;

    }

    div.left-image {

        left: 0%;
        background-image: url('../../assets/img/login/Group_2036.svg'); 
    }

    div.right-image {
      right: 0%;
        background-image: url('../../assets/img/login/Group_2040.svg'); 
    }
 
    span.link:hover {
      font-weight: 700;
      border-bottom: 1px solid;
      cursor: pointer;
    }
    div.return{
      color: $primary-color;
      font-size: 2vw;
      margin-top: 2em;
      cursor: pointer;
    }div.return:hover{
      text-decoration: underline;

    }
  }
}
  @include media-max($layout-breakpoint-xs) {
    div.registration-success-wrapper{
      div.text-wrapper {
        margin-top: 10%;
        letter-spacing: 0.8px;
        width: 90%;
          height: clamp(200px,20vw,20vw);

        .left-image,
        .right-image {
          display: none;
        } 
        .container-text{
          width: 90%;
      
        }
        
      }
      }
    }

